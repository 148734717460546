(function ($) {
  function toggleMenu(menuWrap, body) {
    menuWrap.removeClass('open');
    body.removeClass('menu-open');
  }
  $(function () {
    let mainMenu = $('.menu-wrap-mobile');
    let body = $('body');

    $(window).resize(function () {
      let menuWrap = $('.menu-wrap-mobile');
      if ($(window).width() > 992 && menuWrap.hasClass('open')) {
        $('.menu-toggle-button').removeClass('open');
        menuWrap.removeClass('open');
        body.removeClass('menu-open');
      }
    });

    $('.menu-wrap-mobile .main-menu-mobile ul.menu li > a[href^="#"]').click(function () {
      let menuWrap = $('.menu-wrap-mobile');
      if (menuWrap.hasClass('open')) {
        $('.menu-toggle-button').removeClass('open');
        toggleMenu(menuWrap, body);
      }
    });

    $('.menu-toggle-button').click(function () {
      $(this).toggleClass('open');
      let menuWrap = $('.menu-wrap-mobile');
      if (menuWrap.hasClass('open')) {
        toggleMenu(menuWrap, body);
      } else {
        menuWrap.addClass('open');
        body.addClass('menu-open');
      }
    });
  });
})(jQuery);
