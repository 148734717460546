(function ($) {
  let hoverCta = $('.hover-cta');
  if (!hoverCta.length) return;
  function fixCTAhover(bigger) {
    let bannerHeight = $('.hero_banner').outerHeight();
    hoverCta.css(
      'top',
      bannerHeight +
        100 +
        (bigger ? -140 : (-1 * hoverCta.outerHeight()) / 2) +
        'px'
    );
  }
  $(function () {
    // run on init
    $(window).width() > 992 ? fixCTAhover(true) : fixCTAhover(false);
    let resizeTimeout;
    // Add a timeout so this isn't being called literally all the time
    $(window).resize(function () {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(
        $(window).width() > 992 ? fixCTAhover(true) : fixCTAhover(false),
        250
      );
    });
  });
})(jQuery);
