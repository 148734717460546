(function ($) {
  $(function () {
    if (!$('.gallery-lightbox').length) return;
    $('button[data-gallery="gallery"]').each(function () {
      var width = $(this).width();
      $(this).attr('style', 'height: ' + width + 'px');
    });
    $(document).on('click', '[data-toggle="lightbox"]', function (event) {
      event.preventDefault();
      $(this).ekkoLightbox({
        maxWidth: 1200,
        leftArrow: "<p class='sr-only'>Previous</p>",
        rightArrow: "<p class='sr-only'>Next</p>"
      });
    });
  });
})(jQuery);
